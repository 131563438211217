import React, { useState } from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import { store } from "../stores/store";
import MainNav from "../components/MainNav/MainNav";
import { useStoreState } from "easy-peasy";
import Nommo from "../images/Nommo.png";
import ModalMain from "../components/modal2/modalMain";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Tab1 from "../components/tab1/tab1";
import Tab4 from "../components/tab4/Tab4";
import Tab3 from "../components/tab3/tab3";
import Tab2 from "../components/tab2/tab2";
import BTCPrognose from "../components/BTCprognose/BTCPrognose";
import Footer from "../components/footer/Footer";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";
dayjs.extend(utc);

const useStyles = makeStyles((theme) => ({
  customBadge: {
    backgroundColor: "#b16200",
    color: "white",
    boxShadow: "0px 0px 10px #b16200",
    fontSize: "10px",
  },
}));

const Home = React.memo(() => {
  const classes = useStyles();
  const prognoses = useStoreState((state) => state.prognoses);
  const mobile = useStoreState((state) => state.mobile);
  const [show, setShow] = useState(false);
  const [tabCounter, setCounter] = useState(0);

  return (
    <>
      <div className="Home">
        <div className="containerMy">
          <img className="mask" src={Nommo} alt="nommo" />
          <MainNav mobile={mobile} />
          <div className="bigText">
            <h6>
              Привет, земляне!&nbsp;
              {mobile && <br />}
              Я НОММО.
              <br />
              Используйте мои прогнозы локальных трендов BTC осторожно.
            </h6>
            <div className="learnMore" onClick={() => setShow(true)}>
              &#9432; Узнать больше...
            </div>
            <a
              href="https://t.me/NOMMO_official_RU"
              className="telegram"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 19 17"
                fill="none"
              >
                <path
                  d="M14.871 3.764L7.81 10.509a1.518 1.518 0 0 0-.454.9l-.24
    1.832c-.032.244-.367.269-.432.032l-.925-3.34a.897.897 0 0 1
    .376-.996l8.557-5.416c.153-.097.312.117.18.243zM18.299.036L.33
    7.16c-.443.176-.44.821.006.992l4.377 1.68 1.695 5.6a.51.51 0 0 0
    .819.253l2.44-2.044a.712.712 0 0 1 .887-.026l4.402 3.284a.512.512 0 0 0
    .809-.32L18.989.637c.083-.41-.31-.753-.691-.602z"
                ></path>
              </svg>
              <span>ОБСУДИТЬ В ЧАТЕ</span>
            </a>
          </div>
          <BTCPrognose prognoses={prognoses} />
          <div className="tabs">
            <ul>
              <li
                className={`${tabCounter === 0 && "activeTab"}`}
                onClick={() => setCounter(0)}
              >
                Месяц
              </li>
              <li
                className={`${tabCounter === 1 && "activeTab"}`}
                onClick={() => setCounter(1)}
              >
                <Badge
                  badgeContent={"NEW"}
                  classes={{ badge: classes.customBadge }}
                >
                  День
                </Badge>
              </li>
              <li
                className={`${tabCounter === 2 && "activeTab"}`}
                onClick={() => setCounter(2)}
              >
                История
              </li>
              <li
                className={`${tabCounter === 3 && "activeTab"}`}
                onClick={() => setCounter(3)}
              >
                График
              </li>
            </ul>
          </div>
          <div className="tabsContainer">
            <div
              className={`tab ${tabCounter === 0 && "visible"}`}
              style={{ zIndex: tabCounter === 0 ? "3" : "-1" }}
            >
              <Tab1 />
            </div>
            <div
              className={`tab ${tabCounter === 1 && "visible"}`}
              style={{ zIndex: tabCounter === 1 ? "3" : "-1" }}
            >
              <Tab2 />
            </div>
            <div
              className={`tab ${tabCounter === 2 && "visible"}`}
              style={{
                zIndex: tabCounter === 2 ? "3" : "-1",
                height: mobile ? "270px" : "450px",
              }}
            >
              <Tab3 />
            </div>
            <div
              className={`tab ${tabCounter === 3 && "visible"}`}
              style={{ zIndex: tabCounter === 3 ? "3" : "-1" }}
            >
              <Tab4 />
            </div>
          </div>
          <Footer mobile={mobile} />
        </div>

        <Link to="/login" className="linkToLogin">
          Login
        </Link>
      </div>
      <ModalMain show={show} setShow={setShow} />
    </>
  );
});

Home.getInitialProps = async (props) => {
  if (!props.isServer) {
    store.getActions().set_url(props.match.path);
  }
};

export default Home;
