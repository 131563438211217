import React, { useState, useEffect } from "react";
import "./BTCPrognose.css";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import arUp from "../../images/arrowUp.svg";
import arDown from "../../images/arrowDown.svg";
dayjs.extend(utc);

const BTCPrognose = ({ prognoses }) => {
  const [count, setCount] = useState({ right: 0, all: 0 });
  const [chance, setChance] = useState(0);
  const [nextPrognose, setnextPrognose] = useState({});
  const todayMy = dayjs.utc().utcOffset(3);

  useEffect(() => {
    setChance(0);
    setCount({ right: 0, all: 0 });
    let all = 0;
    let successCount = 0;
    let countAll = 0;
    let right = 0;
    prognoses.forEach((el) => {
      const todayBegin = todayMy.startOf("d").valueOf();
      const todayEnd = todayMy.startOf("d").add(1, "day").valueOf();
      if (
        (dayjs.utc(el.date).utcOffset(3).valueOf() < todayBegin ||
          (dayjs.utc(el.date).utcOffset(3).valueOf() > todayBegin &&
            dayjs.utc(el.date).utcOffset(3).valueOf() < todayEnd &&
            el.avatar)) &&
        !el.sure &&
        el.published
      ) {
        all++;
        if (el.success && !el.sure) successCount++;
      }
      if (
        (dayjs.utc(el.date).utcOffset(3).format("MM/YYYY") ==
          todayMy.format("MM/YYYY") &&
          dayjs.utc(el.date).utcOffset(3).date() < todayMy.date() &&
          !el.sure &&
          el.published) ||
        (dayjs.utc(el.date).utcOffset(3).format("DD/MM/YYYY") ==
          todayMy.format("DD/MM/YYYY") &&
          !el.sure &&
          el.avatar &&
          el.published)
      ) {
        countAll++;
        if (el.success && !el.sure) {
          right++;
        }
      } else {
        if (
          dayjs.utc(el.date).utcOffset(3).format("MM/YYYY") ==
            todayMy.format("MM/YYYY") &&
          dayjs.utc(el.date).utcOffset(3).get("date") > todayMy.get("date") &&
          el.published
        ) {
          setnextPrognose(el);
        }
      }
    });
    setChance(Math.round((successCount / all) * 100));
    setCount({ all: countAll, right: right });
  }, [prognoses]);

  const months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];
  return (
    <div className="counterDiv">
      <div className="counterBeg">
        <div className="month">
          BTC Прогноз {months[dayjs.utc().utcOffset(3).get("month")]}
        </div>
        <div className="counterRight">
          <div className="counterDotto" />
          <div className="counter">
            верно
            <span style={{ color: "#6cd3f5" }}>{count.right}</span> /{" "}
            {count.all}
          </div>
        </div>
      </div>
      {nextPrognose && (
        <div className="counterEnd ">
          <div className="fuckThis">
            <span>Следующий:</span>
            <span className="date">
              {dayjs.utc(nextPrognose.date).utcOffset(3).format("DD.MM")}
            </span>
            <span
              className={`vidget ${
                nextPrognose.sure
                  ? "orange1"
                  : nextPrognose.type === "Up"
                  ? "green1"
                  : "red1"
              }`}
            >
              {nextPrognose.type === "Up" ? "памп" : "дамп"}
              <img
                src={nextPrognose.type === "Up" ? arUp : arDown}
                alt="bla"
                className="pumpArrow"
              />
            </span>
          </div>
          <div className="fuckThis">
            <span>Вероятность:</span>
            <span className="chance">{chance}%</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default BTCPrognose;
