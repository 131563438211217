import React from "react";
import check from "../../images/check.svg";
import error from "../../images/error.svg";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useStoreActions, useStoreState } from "easy-peasy";
import arrowGreen from "../../images/arrows/arrowGreen.svg";
import arrowGreen2 from "../../images/arrows/arrowGreen2.svg";
import arrowRed from "../../images/arrows/arrowRed.svg";
import arrowRed2 from "../../images/arrows/arrowRed2.svg";
import arrowOrange from "../../images/arrows/arrowOrange.svg";
import arrowOrange2 from "../../images/arrows/arrowOrange2.svg";

dayjs.extend(utc);

const CustomBar = React.memo((props) => {
  const mobile = useStoreState((state) => state.mobile);
  const setPicture = useStoreActions((actions) => actions.set_picture);
  const today = dayjs.utc().utcOffset(3);
  const { x, y, width, height, index, date, days } = props;
  const a = height;
  const prcent = (420 * 100) / height;
  let time = null;
  const one = days[index];
  let colorLabel = "rgb(100, 105, 118)";
  let color = "rgb(38, 42, 55)";
  let avatar = null;
  let arrows = [];
  let day = null;
  let arrow;
  let arrow2;
  if (one.prognose) {
    arrows =
      one.prognose.intensity === "Medium"
        ? [0, 1, 2, 3]
        : one.prognose.intensity === "Strong"
        ? [0, 1, 2, 3, 4]
        : [0, 1, 2];
    avatar = one.prognose.avatar;
    day = dayjs.utc(one.prognose.date).utcOffset(3);
    time = dayjs.utc(one.prognose.date).utcOffset(3).format("HH:mm");
    colorLabel = one.prognose.sure
      ? "#b16200"
      : one.prognose.type == "Down"
      ? "#d1276b"
      : "#36dd7d";
    color = one.prognose.sure
      ? "rgb(102, 56, 0)"
      : one.prognose.type == "Down"
      ? "#a30f4a"
      : "#256d4a";
    arrow = one.prognose.sure
      ? arrowOrange
      : one.prognose.type == "Down"
      ? arrowRed
      : arrowGreen;

    arrow2 = one.prognose.sure
      ? arrowOrange2
      : one.prognose.type == "Down"
      ? arrowRed2
      : arrowGreen2;
  }
  const roundSize = mobile ? 18 : 30;

  return (mobile && height > 20) || height > 40 ? (
    <g className={avatar && "hasAvatar"} onClick={() => setPicture(avatar)}>
      <defs>
        <linearGradient id={"Gradient" + index} x1="0" x2="0" y1="0" y2="1">
          <stop
            offset={mobile ? 100 - prcent + 0.7 + "%" : 100 - prcent + "%"}
            stopColor={color}
            stopOpacity="0"
          />
          <stop offset="100%" stopColor={color} />
        </linearGradient>
      </defs>
      <rect
        x={x}
        y={y}
        width={width}
        height={mobile ? height - 20 : height - 40}
        className={`recharts-rectangle`}
        fill={`url(#Gradient${index})`}
      />
      <rect x={x} y={y + a - 40} width={width} height="80" fill="transparent" />
      {one.prognose &&
        (day.date() < today.date() ||
          (day.date() == today.date() && one.prognose.avatar)) && (
          <>
            {one.prognose.success ? (
              <image
                xlinkHref={check}
                x={mobile ? x + 6 : x + 15}
                y={mobile ? y + a - 30 : y + a - 53}
                height={roundSize}
                width={roundSize}
              />
            ) : (
              <image
                xlinkHref={error}
                x={mobile ? x + 6 : x + 15}
                y={mobile ? y + a - 30 : y + a - 53}
                height={roundSize}
                width={roundSize}
              />
            )}
          </>
        )}
      {one.prognose && (
        <>
          {arrows.map((el, index2) => (
            <React.Fragment key={index2}>
              <image
                xlinkHref={arrow}
                className={
                  one.prognose.type == "Down"
                    ? `blinkSecond${index2}`
                    : `blink${index2} revert`
                }
                x={x - 5}
                y={mobile ? y + a - 60 - 10 * el : y + a - 100 - 17 * el}
                height={mobile ? "20" : "40"}
                width={mobile ? "40" : "70"}
              />
              <image
                xlinkHref={arrow2}
                className={
                  one.prognose.type == "Down"
                    ? `blinkSecond${index2}`
                    : `blink${index2} revert`
                }
                x={x - 5}
                y={mobile ? y + a - 60 - 10 * el : y + a - 100 - 17 * el}
                height={mobile ? "20" : "40"}
                width={mobile ? "40" : "70"}
              />
            </React.Fragment>
          ))}
        </>
      )}
      {time && (
        <text
          style={{
            fill: "rgb(108, 211, 245)",
            fontSize: mobile ? "8px" : "12px",
          }}
          x={mobile ? x + 2 : x + 10}
          y={mobile ? y + a - 240 : y + a - 385}
        >
          >{time}
        </text>
      )}
      <text
        className="dateMy"
        style={{ fill: colorLabel }}
        x={mobile ? x + 4 : x + 15}
        y={y + a}
      >
        {date}
      </text>
    </g>
  ) : (
    <></>
  );
});

export default CustomBar;
