import React from "react";
import { useStoreState, useStoreActions } from "easy-peasy";

const CreateCustomDot = React.memo((props) => {
  const mobile = useStoreState((state) => state.mobile);
  const setPrice = useStoreActions((actions) => actions.set_price);
  const setTop = useStoreActions((actions) => actions.set_top);
  const { cx, cy, index, value } = props;
  if (index == props.length - 1) {
    setPrice(value);
    setTop(cy);
  }
  return (
    <>
      {index == props.length - 1 ? (
        <svg
          width="100"
          height="100"
          x={cx - 50}
          y={cy - 50}
          className="showIcon"
        >
          <g>
            <circle
              cx="50"
              cy="50"
              r={mobile ? "3.5" : "6.5"}
              style={{
                strokeWidth: mobile ? "1.5" : "2",
                fill: "#164fc5",
                stroke: "#5fd9fd",
              }}
            />
            <circle
              cx="50"
              cy="50"
              r={mobile ? "8.5" : "12.5"}
              style={{
                strokeWidth: mobile ? "1.5" : "2",
                fill: "none",
                stroke: "#5fd9fd",
              }}
            />
            <circle
              cx="50"
              cy="50"
              r={mobile ? "12.5" : "16.5"}
              style={{
                strokeWidth: mobile ? "1.5" : "2",
                fill: "none",
                stroke: "#5fd9fd",
              }}
            />
          </g>
        </svg>
      ) : (
        <></>
      )}
    </>
  );
});

export default CreateCustomDot;
