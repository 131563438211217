import React from "react";
import "./Tab4.css";

const Tab4 = () => {
  return (
    <div className="graphContainer"
    >
      <iframe
        title="bla"
        id="tradingview_ab5ec"
        src="https://s.tradingview.com/widgetembed/?frameElementId=tradingview_ab5ec&amp;symbol=BITFINEX%3ABTCUSD&amp;interval=30&amp;hidetoptoolbar=1&amp;hidelegend=1&amp;saveimage=0&amp;toolbarbg=f1f3f6&amp;studies=%5B%5D&amp;theme=Dark&amp;style=1&amp;timezone=Europe%2FMoscow&amp;studies_overrides=%7B%7D&amp;overrides=%7B%7D&amp;enabled_features=%5B%5D&amp;disabled_features=%5B%5D&amp;locale=en&amp;utm_source=predictionlab.exrates.me&amp;utm_medium=widget&amp;utm_campaign=chart&amp;utm_term=BITFINEX%3ABTCUSD"
        style={{
          width: "100%",
          height: "100%",
          margin: "0 !important",
          padding: "0 !important",
        }}
        frameBorder="0"
        allowtransparency="true"
        scrolling="no"
      />
    </div>
  );
};

export default Tab4;
