import React, { useEffect } from "react";
import "./tab1.css";
import { ComposedChart, Bar, Line, YAxis } from "recharts";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import CreateCustomDot from "./CustomDot";
import SidePrice from "./SidePrice";
import CustomBar from "./CustomBar";
import { useStoreState, useStoreActions } from "easy-peasy";
dayjs.extend(utc);
let axisPorps = [];
let animate = true;
let firstTime = true;

const Tab1 = React.memo(() => {
  const prognoses = useStoreState((state) => state.prognoses);
  const pushTick = useStoreActions((action) => action.set_ticks);
  const daysProp = useStoreState((state) => state.days);
  const today = dayjs.utc().utcOffset(3);
  const mobile = useStoreState((state) => state.mobile);

  if (firstTime) {
    firstTime = false;
  } else {
    animate = false;
  }

  useEffect(() => {
    const target = document.querySelector("#needMouseScroll");
    if (mobile) {
      if (today.date() > 9 && today.date() < 18) {
        target.scrollLeft += 30 * 8;
      } else if (today.date() > 17 && today.date() < 25) {
        target.scrollLeft += 30 * 18;
      } else if (today.date() > 24) {
        target.scrollLeft += 1000;
      }
    } else {
      if (today.date() > 17) {
        target.scrollLeft += 1000;
      }
    }

    target.addEventListener("wheel", (event) => {
      const toLeft = event.deltaY < 0 && target.scrollLeft > 0;
      const toRight =
        event.deltaY > 0 &&
        target.scrollLeft < target.scrollWidth - target.clientWidth;

      if (toLeft || toRight) {
        event.preventDefault();
        target.scrollLeft += event.deltaY;
      }
    });
  }, [mobile]);

  let days = daysProp.filter((el) => {
    return (
      dayjs
        .utc(el.time)
        .utcOffset(3)
        .month() === today.month()
    );
  });

  days = days.reverse();

  // for (let bla of days) {
  //   console.log(bla.time);
  //   console.log(dayjs.utc(bla.time).utcOffset(3).format("DD.MM.YYYY"))
  // }
  const amountOfDays = days.length + 1;
  const maxData = Math.max(
    ...days.map((el) => {
      return el.close;
    })
  );
  const minData = Math.min(
    ...days.map((el) => {
      return el.close;
    })
  );
  for (let i = 0; i < today.daysInMonth(); i++) {
    if (!days[i]) days[i] = {};
    days[i].checkDate = `${(i + 1).toString().length < 2 ? "0" : ""}${i + 1}.${(today.month() + 1).toString().length < 2 ? "0" : ""
      }${today.month() + 1}.${today.year()}`;
    const ind = prognoses
      .map((el) => dayjs.utc(el.date).utcOffset(3).format("DD.MM.YYYY"))
      .indexOf(days[i].checkDate);
    if (ind > -1 && prognoses[ind].published) {
      days[i].prognose = prognoses[ind];
    }
    days[i].date = `${(i + 1).toString().length < 2 ? "0" : ""}${i + 1}.${(today.month() + 1).toString().length < 2 ? "0" : ""
      }${today.month() + 1}`;
    days[i].bar = 100000;
  }
  days.unshift(days[0]);
  const diffr = maxData - minData;
  let extremum;

  if (diffr > 25000) {
    extremum = 6500;
  } else if (diffr > 15000) {
    extremum = 4500;
  } else if (diffr > 10000) {
    extremum = 2500;
  } else if (diffr > 5000) {
    extremum = 1800;
  } else {
    extremum = 500;
  }
  return (
    <>
      <div className="mainTab1 superScroll" id="needMouseScroll">
        <ComposedChart
          width={mobile ? 35 * days.length : 70 * days.length}
          height={mobile ? 270 : 420}
          data={days}
          margin={{ left: mobile ? -35 : -70 }}
        >
          <defs>
            <filter id="shadow" height="200%">
              <feDropShadow
                dx="0"
                dy="4"
                stdDeviation="10"
                floodColor="#002ff7"
              />
            </filter>
          </defs>
          <YAxis
            orientation="right"
            axisLine={false}
            interval={0}
            type="number"
            allowDataOverflow={true}
            domain={[minData - extremum, maxData + extremum]}
            tickLine={false}
            tick={(props) => {
              console.log('@@@@@@@@ ', props);
              axisPorps.push(props);
              if (axisPorps.length == 4) {
                pushTick(axisPorps);
                axisPorps = [];
              }
            }}
          />
          <Bar
            dataKey="bar"
            barSize={mobile ? 35 : 60}
            fill="#413ea0"
            shape={<CustomBar days={days} />}
          ></Bar>
          <Line
            type="monotone"
            dataKey="close"
            isAnimationActive={animate}
            stroke="#5fd9fd"
            strokeWidth={3}
            filter="url(#shadow)"
            dot={<CreateCustomDot length={amountOfDays} />}
          />
        </ComposedChart>
      </div>
      <div className="secondMain">
        <SidePrice />
      </div>
    </>
  );
});

export default Tab1;
