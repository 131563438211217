import React from "react";
import { useStoreState } from "easy-peasy";

const SidePrice = React.memo(() => {
  const mobile = useStoreState((state) => state.mobile);
  const ticks = useStoreState((state) => state.ticks);
  const price = useStoreState((state) => state.price);
  const top = useStoreState((state) => state.top);

  console.log('%%%%%%%%%%%', ticks);
  return (
    <>
      <svg className="dashed-line showIcon" style={{ top: top + "px" }}>
        <line x1="0" x2="10000" y1="0" y2="0" strokeWidth="1" />
      </svg>
      <span
        className="mainPrice"
        style={{ top: mobile ? top - 6 + "px" : top - 10 + "px" }}
      >
        <span className="shadowBox"></span>
        <svg
          className="priceBox"
          x="0px"
          y="0px"
          viewBox="0 0 168.9 65.5"
          xmlSpace="preserve"
        >
          <g>
            <polygon
              className="st3"
              points="29.7,64.5 1.3,31.5 29.7,1 187.9,1 187.9,64.5   "
            ></polygon>
            <path
              className="st4"
              d="M186.9,2v61.5H30.2l-27.5-32L30.1,2H186.9 M188.9,0H29.3L0,31.5l29.3,34.1h159.6V0L188.9,0z"
            ></path>
          </g>
        </svg>
        <span className="priceItself">{"$" + Math.floor(price)}</span>
      </span>
      {ticks.map((el, ind) => (
        <span key={ind} className="price" style={{ bottom: 450 - el.y + "px" }}>
          {"$" + Math.floor(el.payload.value / 10) * 10}
        </span>
      ))}
    </>
  );
});

export default SidePrice;
