import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import "./tab3.css";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import check from "../../images/check.svg";
import error from "../../images/error.svg";
dayjs.extend(utc);

const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

const Tab3 = () => {
  const prognoses = useStoreState((state) => state.prognoses);
  const setPicture = useStoreActions((actions) => actions.set_picture);
  const collection = [];
  let prognosesLocal = [];

  let count = 0;
  let dayOne;
  prognosesLocal = prognoses.filter((el) => {
    return el.avatar;
  });
  if (prognosesLocal && prognosesLocal.length > 0) {
    dayOne = dayjs.utc(prognosesLocal[0].date).utcOffset(3).format("MM/YYYY");
    collection.push([prognosesLocal[0]]);
    for (let i = 1; i < prognosesLocal.length; i++) {
      const dayTwo = dayjs
        .utc(prognosesLocal[i].date)
        .utcOffset(3)
        .format("MM/YYYY");
      if (dayOne === dayTwo) {
        collection[count].push(prognosesLocal[i]);
      } else {
        count++;
        dayOne = dayjs
          .utc(prognosesLocal[i].date)
          .utcOffset(3)
          .format("MM/YYYY");
        collection[count] = [prognosesLocal[i]];
      }
    }
  }

  return (
    <div className="mainTab3 superScroll">
      {collection.map((one, index) => (
        <div className="innerMain" key={index}>
          <div style={{ marginBottom: "20px", textAlign: "left" }}>
            {months[dayjs.utc(one[0].date).utcOffset(3).get("month")]}{" "}
            {dayjs.utc(one[0].date).utcOffset(3).format("YYYY")}
          </div>
          <div className="prContainer">
            {one.map((bla) => (
              <div
                className="dotto"
                onClick={() => setPicture(bla.avatar)}
                key={bla._id}
              >
                <img
                  src={bla.success ? check : error}
                  style={{ width: "31px", marginRight: "8px" }}
                  alt=""
                />
                {dayjs.utc(bla.date).utcOffset(3).format("DD")}{" "}
                {months[dayjs.utc(bla.date).utcOffset(3).get("month")]}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Tab3;
