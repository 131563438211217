import React from "react";
import "./MainNav.css";
import img from "../../images/logo.png";
import share from "../../images/share.svg";
import bitok from "../../images/bitok.svg";
import bell from "../../images/bell.svg";

const MainNav = ({ mobile }) => (
  <header className="mainNav">
    <div className="firstPart">
      <img src={img} alt="logo" />
      <div>ARK OF NOMMO</div>
    </div>
    <div className="middlebuttn">
      <div className="dropdown">
        <span>АРЕНА</span>
        <div
          className="ulContainer"
          style={mobile ? { left: "-35px" } : { left: "-25px" }}
        >
          <ul className="dropdownUl" style={{ marginBottom: "20px" }}>
            <li
              onClick={() =>
                window.open(
                  "https://partner.bybit.com/b/100x2v2s2",
                  "_blank"
                )
              }
            >
              Bybit 100x 2.2
            </li>
            <li className="divider"></li>
            <li
              onClick={() =>
                window.open(
                  "https://partner.bybit.com/b/100xchallenge",
                  "_blank"
                )
              }
            >
              Bybit 100x
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="buttonsEnd">
      <div className="dropdown">
        <img src={bell} style={{ width: "33px" }} />
        <div
          className="ulContainer"
          style={mobile ? { left: "-58px" } : { left: "-58px" }}
        >
          <ul className="dropdownUl" style={{ marginBottom: "50px" }}>
            <li
              onClick={() =>
                window.open(
                  "https://t.me/nommo_subnot_bot?start=fKpa0Nan",
                  "_blank"
                )
              }
            >
              Уведомления
            </li>
          </ul>
        </div>
      </div>
      <div className="dropdown">
        <img src={share} style={{ width: "27px", height: "20px" }} />
        <div className="ulContainer" style={{ left: "-55px" }}>
          <ul className="dropdownUl">
            <li
              onClick={() =>
                window.open(
                  "http://www.facebook.com/sharer.php?u=https://nommo.io",
                  "displayWindow",
                  "width=520,height=300,left=350,top=170,status=no,toolbar=no,menubar=no"
                )
              }
            >
              Facebook
            </li>
            <li className="divider"></li>
            <li
              onClick={() =>
                window.open(
                  "https://telegram.me/share/url?url=https://nommo.io",
                  "_blank"
                )
              }
            >
              Telegram
            </li>
            <li className="divider"></li>
            <li
              onClick={() =>
                window.open("https://www.twitter.com/share", "_blank")
              }
            >
              Twitter
            </li>
          </ul>
        </div>
      </div>
      <div className="dropdown">
        <img src={bitok} style={{ width: "25px" }} />
        <div
          className="ulContainer"
          style={mobile ? { left: "-58px" } : { left: "-58px" }}
        >
          <ul className="dropdownUl" style={{ marginBottom: "50px" }}>
            <li
              onClick={() =>
                window.open(
                  "https://t.me/HermesCrypto_Bot?start=fKpa0Nan",
                  "_blank"
                )
              }
            >
              Обмен
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
);
export default MainNav;
