import React from "react";
import "./modalMain.css";
import CloseIcon from "@material-ui/icons/Close";
import Fade from "@material-ui/core/Fade";

const ModalMain = ({ show, setShow }) => {
  return (
    <Fade in={show} timeout={500}>
      <div className="overlay2" onClick={() => setShow(false)}>
        <div className="modalText">
          <p>
            Вполне вероятно (но пока не точно) нам удалось создать грааль с помощью инопланетной технологии NOMMO, который как мы надеемся, поможет вам ориентироваться в реалиях крипторынка.
          </p>
          <p>
            Эта технология прогнозирует локальные тренды цены Биткоина по датам и времени в течение календарного месяца Земли, где Памп и Дамп - это резкие импульсы изменения цены, которые происходят после и около указанного времени, указанной даты, в часовом поясе UTC+3 (Мск).
          </p>
          <p>
            Биткоин является основой крипторынка с доминацией более 50%, и изменение его цены напрямую влияет на состояние всех альткоинов и рынка в целом. Часто результаты прогнозов отражают состояние всего рынка.
          </p>
          <p>
            Обратите внимание на количество стрелок-делений отмеченных в дни прогнозов - оно показывает ожидаемую интенсивность импульса относительно средних значений ближайших дней.
          </p>
          <p>
            Красным и зеленым отмечены прогнозы с наибольшей вероятностью и стабильными показателями по бэктесту. Оранжевым отмечены тестовые прогнозы по новым расчетам и показателям, когда предполагаемые направление и интенсивность менее точны и/или сама вероятность импульса мала. Результаты тестовых прогнозов не учитываются в статистике вероятности.
          </p>
          <p>
            Во вкладке ДЕНЬ показаны дополнительные внутридневные колебания текущего дня со средней вероятностью, интенсивность которых часто меньше основных импульсов. Они показывают направление локального тренда в моменте времени, оранжевым отмечена вероятная волатильность без точного направления.
          </p>
          <p>
            !!! Все наши прогнозы - это эксперимент и являются лишь дополнением к вашему собственному исследованию, не являются финансовой рекомендацией. Прогнозы относятся только к указанным датам и ближайшему времени, а не к временным интервалам между ними - когда что угодно еще может произойти. В течение месяца возможны изменения исходя из текущей ситуации.
          </p>
          <p>
            В связи со сложностью адаптации инопланетных технологий к человеческим, система в настоящее время находится в процессе тестирования.
          </p>
        </div>
        <div className="iconClose">
          <CloseIcon fontSize="inherit" />
        </div>
      </div>
    </Fade>
  );
};

export default ModalMain;
