import React from "react";
import "./tab2.css";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
import icon from "../../images/iconUp.svg";
import arrowGreen from "../../images/arrows/arrowGreen.svg";
import arrowGreen2 from "../../images/arrows/arrowGreen2.svg";
import arrowRed from "../../images/arrows/arrowRed.svg";
import arrowRed2 from "../../images/arrows/arrowRed2.svg";
import arrowOrange from "../../images/arrows/arrowOrange.svg";
import arrowOrange2 from "../../images/arrows/arrowOrange2.svg";
import { useStoreState } from "easy-peasy";

const Tab2 = () => {
  const days = useStoreState((state) => state.daysModel);
  const today = dayjs.utc().utcOffset(3);
  const localDays = days
    .filter((el) => {
      return (
        dayjs.utc(el.date).utcOffset(3).format("DD.MM.YYYY") ===
        today.format("DD.MM.YYYY")
      );
    })
    .sort(function (a, b) {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

  return (
    <div className="mainTab2 superScroll">
      <ul className="innerMain">
        {localDays.map((el) => (
          <li key={el._id}>
            <span>>{dayjs.utc(el.date).utcOffset(3).format("HH:mm")}</span>
            {el.parameter == "Up" ? (
              <span className="green2 iconContainer">
                <div className="twoIcons">
                  <div className="blink0 oneIcon">
                    <img src={arrowGreen} alt="" className="revert2" />
                    <img src={arrowGreen2} alt="" className="revert2" />
                  </div>
                  <div className="blink1 oneIcon">
                    <img src={arrowGreen} alt="" className="revert2" />
                    <img src={arrowGreen2} alt="" className="revert2" />
                  </div>
                </div>
              </span>
            ) : el.parameter == "Down" ? (
              <span className="red2 iconContainer">
                <div className="twoIcons">
                  <div className="blink0 oneIcon">
                    <img src={arrowRed} alt="" />
                    <img src={arrowRed2} alt="" />
                  </div>
                  <div className="blink1 oneIcon">
                    <img src={arrowRed} alt="" />
                    <img src={arrowRed2} alt="" />
                  </div>
                </div>
              </span>
            ) : (
              <span className="orange2 iconContainer">
                <div className="twoIcons">
                  <div className="blink0 oneIcon">
                    <img src={arrowOrange} alt="" className="revert2" />
                    <img src={arrowOrange2} alt="" className="revert2" />
                  </div>
                  <div className="blink1 oneIcon">
                    <img src={arrowOrange} alt="" />
                    <img src={arrowOrange2} alt="" />
                  </div>
                </div>
              </span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tab2;
